import { Nullable } from '@shared/types/nullable';
import { Pagination } from './common.model';

export interface ICasesState {
  cases?: CaseListResponse;
  loading?: boolean;
  activeTab?: number;
  paginationObj: any;
  searchTxt?: string | '';
  searchObj?: CaseListSearch | {};
  error?: any;
  opSuccess?: string;
  opFail?: string;
  caseDetail: { caseDetail: CaseItem };
  case: CaseItem;
  events: CaseEventApiListResponse;
  updateSuccess: boolean
}

export interface CaseListResponse {
  data: any[];
  meta: Pagination | undefined;
}

export interface CaseListApiResponse {
  totalCount: number;
  totalPages: number;
  pageNumber: number;
  items: CaseItem[];
}

export interface CaseListSearch {
  siteId: number;
  dateFrom: Date;
  dateTo: Date;
  status?: string;
}

export interface CaseDetailObject {
  loadId: number;
  status: string;
  truck: { regNumber: string };
  transporter: number;
  destinationSite: { code: string };
  originSite: { code: string };
  weighbridgeDoc: { nettWeight: number };
  type: string;
  createdAt: string;
}

export interface ImageData {
  id: number;
  loadEventId: number;
  type: string;
  externalId: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
  createdBy: string;
  imageUrl: string;
  lat: number;
  long: number;
  displayValue?: string;
  status?: string;
}

export interface GetCaseDetail {
  id: string;
}

export interface CaseById {
  id: string;
  pagination?: Pagination
  filter?: {
    sortDir: string
  }
}

export interface LoadListApiResponse {
  totalCount: number;
  totalPages: number;
  pageNumber: number;
  items: CaseItem[];
}

export interface CaseEventApiListResponse {
  totalCount: number;
  totalPages: number;
  pageNumber: number;
  items: CaseEventItem[];
}

export interface CaseEventList {
  cases: CaseEventItem[];
}

export interface CaseEventItem {
  id: number;
  caseId: number;
  content: string,
  createdAt: string
  createdBy: {
    id: number;
    uid: string;
    firstName: string;
    lastName: string;
    organizationId: number;
    lastActive: null;
    createdAt: string;
    updatedAt: string;
  },
  type: string
}

export interface ParamUpdateCase {
  caseId: number,
  loadId?: number,
  quarantineSiteId?: number,
  responsibleParty?: string,
  caseStatus?: string,
  oldCaseOutcome?: string,
  loadOutcome?: string,
  loadStatus?: string,
}


export interface ParamCaseEvents {
  id: string,
  pagination: Pagination
  filter?: {
    sortDir: string,
  }
}


export interface QuarantineSite {
  id: number;
  displayName: string;
  code: string;
  latitude: number;
  longitude: number;
  type: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}


export interface CreatedBy {
  id: number;
  uid: string;
  firstName: string;
  lastName: string;
  organizationId: number;
  lastActive: null;
  createdAt: string;
  updatedAt: string;
}

export interface UpdatedBy {
  id: number;
  uid: string;
  firstName: string;
  lastName: string;
  organizationId: number;
  lastActive: null;
  createdAt: string;
  updatedAt: string;
}

export interface CaseItem {
  id: number;
  loadId: number;
  quarantineSite: Nullable<QuarantineSite>;
  responsibleParty: string;
  status: string;
  outcome: string;
  resolvedAt: string;
  createdAt: string;
  updatedAt: string;
  createdBy: CreatedBy;
  updatedBy: UpdatedBy;
}

export interface ParamCreateCaseEvent {
  eventType: string;
  content: string;
  id: number;
}

export const InitialStateValue: ICasesState = {
  cases: { data: [], meta: undefined },
  loading: false,
  error: null,
  activeTab: 0,
  paginationObj: {
    sortField: 'createdAt',
    sortDir: 'DESC',
    pageNumber: 0,
    pageSize: 20,
  },
  updateSuccess: false,
  searchTxt: '',
  searchObj: {},
  case: {
    loadId: 0,
    status: '',
    createdAt: '',
    quarantineSite: {
      id: 0,
      displayName: '',
      code: '',
      latitude: 0,
      longitude: 0,
      type: '',
      createdAt: '',
      updatedAt: '',
      createdBy: '',
      updatedBy: '',
    },
    responsibleParty: '',
    outcome: '',
    resolvedAt: '',
    id: 0,
    updatedAt: '',
    createdBy: {
      id: 0,
      uid: '',
      firstName: '',
      lastName: '',
      organizationId: 0,
      lastActive: null,
      createdAt: '',
      updatedAt: '',
    },
    updatedBy: {
      id: 0,
      uid: '',
      firstName: '',
      lastName: '',
      organizationId: 0,
      lastActive: null,
      createdAt: '',
      updatedAt: '',
    },
  },
  events: { items: [], totalCount: 0, totalPages: 0, pageNumber: 0 },
  caseDetail: {
    caseDetail: {
      loadId: 0,
      status: '',
      createdAt: '',
      quarantineSite: {
        id: 0,
        displayName: '',
        code: '',
        latitude: 0,
        longitude: 0,
        type: '',
        createdAt: '',
        updatedAt: '',
        createdBy: '',
        updatedBy: '',
      },
      responsibleParty: '',
      outcome: '',
      resolvedAt: '',
      id: 0,
      updatedAt: '',
      createdBy: {
        id: 0,
        uid: '',
        firstName: '',
        lastName: '',
        organizationId: 0,
        lastActive: null,
        createdAt: '',
        updatedAt: '',
      },
      updatedBy: {
        id: 0,
        uid: '',
        firstName: '',
        lastName: '',
        organizationId: 0,
        lastActive: null,
        createdAt: '',
        updatedAt: '',
      },
    },
  },
};
