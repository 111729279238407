/** effect for user list api call */

import { Injectable } from '@angular/core';
import { SortSearchAndPaginationModal } from '@models/common.model';
import {
  GetLoadDetail,
  ImageData,
  LoadById,
  LoadEventList,
  ParamUpdateLoad,
} from '@models/loads.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadAPIService } from '@services/load.service';
import { catchError, map, mergeMap, of } from 'rxjs';
import {
  getLoadDetail,
  getLoadDetailById,
  getLoadDetailByIdFail,
  getLoadDetailByIdSuccess,
  getLoadDetailFail,
  getLoadDetailSuccess,
  loadLoadError,
  loadLoadSuccess,
  loadLoadWithSearch,
  loadLoads,
  resetStateAfterOperation,
  resetStateSuccess,
  updateLoad,
  updateLoadError,
  updateLoadSuccess,
} from '../actions/load.action';

@Injectable()
export class LoadAPIEffects {
  loadLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLoads),
      mergeMap((param: SortSearchAndPaginationModal) =>
        this.loadAPIService.getLoads(param).pipe(
          map((loads) => {
            return loadLoadSuccess({ loads });
          }),
          catchError(async (error) => loadLoadError({ error }))
        )
      )
    )
  );

  loadLoadWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLoadWithSearch),
      mergeMap((param: any) => {
        return this.loadAPIService.getLoads(param).pipe(
          map((loads) => {
            return loadLoadSuccess({ loads });
          }),
          catchError(async (error) => loadLoadError({ error }))
        );
      })
    )
  );

  getLoadDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLoadDetail),
      mergeMap((param: GetLoadDetail) =>
        this.loadAPIService.getLoadDetail(param).pipe(
          map((load) => {
            // map images from the event objects
            let arrayToRender: any = load.loads.reduce(
              (group: any, data: LoadEventList) => {
                const { regNumber } = data;

                regNumber
                  ? (group[regNumber] = group[regNumber] ?? [])
                  : (group['event'] = group['event'] ?? []);

                if (data.images[0]) {
                  var obj = {
                    ...data.images[0],
                    site: data.site,
                    lat: data.latitude ? data.latitude : '-',
                    long: data.longitude ? data.longitude : '-',
                    displayValue: this.getImageDisplayValue(data.type, data),
                    status: data.status,
                  };

                  regNumber
                    ? group[regNumber].push(obj)
                    : group['event'].push(obj);
                }

                return group;
              },
              {}
            );

            load.loads.map((val: any, key: any) => {
              val.images = val.regNumber
                ? this.fecthCorrespondingImages(
                    val.type,
                    arrayToRender[val.regNumber]
                  )
                : arrayToRender['event'];
            });

            // loop to remove the duplicates from the array
            let keysToCombine = ['type', 'regNumber'];

            let filtered: any = load.loads.filter(
              (
                (uniqueValuesSet) => (currentObject: { [key: string]: any }) =>
                  ((combinedKey) =>
                    !uniqueValuesSet.has(combinedKey) &&
                    uniqueValuesSet.add(combinedKey))(
                    keysToCombine.map((key) => currentObject[key]).join('|')
                  )
              )(new Set())
            );

            filtered.map((val: any) => {
              if (val.type == 'CAPTURE_DL_FRONT') {
                val.images = val.images.filter(
                  (imgVal: ImageData) => imgVal.type === 'DL_FRONT'
                );
              }
              if (val.type == 'CAPTURE_DL_BACK') {
                val.images = val.images.filter(
                  (imgVal: ImageData) => imgVal.type === 'DL_BACK'
                );
              }
              return val;
            });

            let loadData = { loads: filtered };

            return getLoadDetailSuccess({ load: loadData });
          }),
          catchError(async (error) => {
            return getLoadDetailFail({ error });
          })
        )
      )
    )
  );

  fecthCorrespondingImages(type: string, imageList: ImageData[]): ImageData[] {
    switch (type) {
      case 'CAPTURE_HORSE_REG':
        return imageList.filter((image) => image.type === 'HORSE_REG');

      case 'CAPTURE_SEAL':
        return imageList.filter((image) => image.type === 'SEAL');

      case 'CAPTURE_TRAILER_REG':
        return imageList.filter((image) => image.type === 'TRAILER_REG');

      default:
        return []; // Default value if the type doesn't match any case
    }
  }

  getImageDisplayValue(type: string, data: LoadEventList): string {
    switch (type) {
      case 'CAPTURE_HORSE_REG':
        return data.regNumber ? data.regNumber : '-';

      case 'CAPTURE_DL_FRONT':
        return data.licenseNumber ? data.licenseNumber.toString() : '-';

      case 'CAPTURE_DL_BACK':
        return data.licenseNumber ? data.licenseNumber.toString() : '-';

      case 'CAPTURE_TRAILER_REG':
        return data.regNumber ? data.regNumber : '-';

      case 'CAPTURE_SEAL':
        return data.sealNumber ? data.sealNumber : '-';

      case 'CAPTURE_WEIGHBRIDGE_DOC':
        return data.weighbridgeRefNo ? data.weighbridgeRefNo : '-';

      default:
        return ''; // Default value if the type doesn't match any case
    }
  }

  getLoadDetailById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getLoadDetailById),
      mergeMap((param: LoadById) =>
        this.loadAPIService.getLoadDetailById(param).pipe(
          map((loadDetail) => {
            return getLoadDetailByIdSuccess(loadDetail);
          }),
          catchError(async (error) => {
            return getLoadDetailByIdFail({ error });
          })
        )
      )
    );
  });

  uploadLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateLoad),
      mergeMap((param: ParamUpdateLoad) =>
        this.loadAPIService.updateLoad(param).pipe(
          map(() => {
            return updateLoadSuccess();
          }),
          catchError(async (error) => {
            return updateLoadError({ error });
          })
        )
      )
    );
  });

  resetStateAfterOperation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetStateAfterOperation),
      mergeMap(() => {
        return of(resetStateSuccess());
      })
    );
  });

  constructor(
    private actions$: Actions,
    private loadAPIService: LoadAPIService
  ) {}
}
