/**
 * API call for the site management services
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  GetLoadDetail,
  LoadById,
  LoadEventList,
  LoadItem,
  LoadListApiResponse,
  LoadListResponse,
  ParamUpdateLoad,
} from '@models/loads.model';
import { environment } from '@environment/environment';
import { CaseManagementAPIConstant, LoadManagementAPIConstant } from 'src/app/constant/api.constants';
import {
  isString,
  SortSearchAndPaginationModal,
} from '@models/common.model';

@Injectable({
  providedIn: 'root',
})
export class LoadAPIService {
  constructor(private http: HttpClient) {}

  /** List **/
  getLoads(param: SortSearchAndPaginationModal): Observable<LoadListResponse> {
    const pageNumber = (param.pagination && param.pagination.currentPage) || 0;
    const pageSize = (param.pagination && param.pagination.pageSize) || 20;
    const sortField =
      param.pagination && param.pagination.sortField
        ? param.pagination.sortField
        : 'createdAt';
    const sortDir =
      param.pagination && param.pagination.sortDir
        ? param.pagination.sortDir
        : 'DESC';
    const queryString = isString(param.search) ? param.search : '';
    const siteId =
      param.filter && param.filter.siteId ? param.filter.siteId : '';
    const dateFrom =
      param.filter && param.filter.dateFrom ? param.filter.dateFrom : '';
    const dateTo =
      param.filter && param.filter.dateTo ? param.filter.dateTo : '';
    let queryPara: string = '';
    if (sortField) {
      queryPara = `?sortField=${sortField}&sortDir=${sortDir.toUpperCase()}&pageNumber=${pageNumber}&pageSize=${pageSize}&queryString=${queryString?.toString()}&siteId=${siteId}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    } else {
      queryPara = `?sortDir=${sortDir}&pageNumber=${pageNumber}&pageSize=${pageSize}&queryString=${queryString?.toString()}&siteId=${siteId}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    }

    return this.http
      .get<LoadListApiResponse>(
        environment.apiBaseUrl + LoadManagementAPIConstant.LOADS + queryPara
      )
      .pipe(
        map((apiResponse: LoadListApiResponse) => {
          const modifiedItems = apiResponse.items.map((item) => {
            let mappedStatus;
            switch (item.status) {
              case 'CREATED':
                mappedStatus = 'Created';
                break;
              case 'LOADING':
                mappedStatus = 'Loading';
                break;
              case 'IN_TRANSIT':
                mappedStatus = 'In Transit';
                break;
              case 'UN_LOADING':
                mappedStatus = 'Unloading';
                break;
              case 'CLOSED':
                mappedStatus = 'Closed';
                break;
              case 'EXCEPTION':
                mappedStatus = 'Exception';
                break;
              case 'CANCELED':
                mappedStatus = 'Cancelled';
                break;
              case 'SUSPENDED':
                mappedStatus = 'Suspended';
                break;
              case 'DISCARDED':
                mappedStatus = 'Discarded';
                break;
              default:
                mappedStatus = 'Unknown';
                break;
            }

            return {
              ...item,
              status: mappedStatus,
            };
          });

          return {
            data: modifiedItems,
            meta: {
              itemCount: apiResponse.totalCount,
              pageCount: apiResponse.totalPages,
              currentPage: apiResponse.pageNumber,
              pageSize: pageSize,
            },
          };
        })
      );
  }

  /**load details */
  getLoadDetail(param: GetLoadDetail): Observable<{ loads: LoadEventList }> {
    const loadId = param.id;
    const processType = param.processType;
    return this.http
      .get<LoadEventList>(
        environment.apiBaseUrl +
          LoadManagementAPIConstant.LOADS +
          '/' +
          loadId +
          '/events?processType=' +
          processType
      )
      .pipe(
        map((apiResponse: LoadEventList) => {
          return {
            loads: apiResponse,
          };
        })
      );
  }

  updateLoad(param: ParamUpdateLoad): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiBaseUrl}${CaseManagementAPIConstant.CASES}/${param.caseId}/loads/${param.loadId}`,
      {
        type: 'STATUS',
        prevValue: param.prevValue,
        nextValue: param.loadStatus,
        attributes: {}
      }
    );
  }

  /** get load detail from id */
  getLoadDetailById(param: LoadById): Observable<{ loadDetail: LoadItem }> {
    return this.http
      .get<LoadItem>(
        environment.apiBaseUrl +
          LoadManagementAPIConstant.LOADS +
          '/' +
          param.id
      )
      .pipe(
        map((apiResponse: LoadItem) => {
          return {
            loadDetail: apiResponse,
          };
        })
      );
  }
}
