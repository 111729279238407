import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TENANT_ID } from '@angular/fire/compat/auth';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AppComponent } from './app.component';
import { AuthModule } from '@module/auth/auth.module';
import { SharedModule } from '@shared/shared.module';
import { environment } from '@environment/environment';
import {
  AngularFireRemoteConfigModule,
  SETTINGS,
  AngularFireRemoteConfig,
} from '@angular/fire/compat/remote-config';
import { EffectsModule } from '@ngrx/effects';
import { AppEffect, AppReducer } from './ngrx/app.state';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ErrorInterceptor } from '@interceptor/error-interceptor';
import { CommonModule } from '@angular/common';
import { HTTPInterceptor } from '@interceptor/http.interceptor';

@NgModule({ declarations: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserModule,
        SharedModule,
        RouterModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(AppReducer),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireRemoteConfigModule,
        AngularFireStorageModule,
        AuthModule.forRoot(),
        EffectsModule.forRoot(AppEffect),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: !isDevMode(), // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
            trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
            traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
        }),
        CommonModule], providers: [
        { provide: TENANT_ID, useValue: environment.tenantId },
        {
            provide: SETTINGS,
            useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10000 } : {},
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })

export class AppModule {
  constructor(private remoteConfig: AngularFireRemoteConfig) {
    this.remoteConfig.fetchAndActivate();
  }
}
