import { LoadAPIEffects } from '@effects/load.effects';
import { CaseAPIEffects } from '@effects/case.effect';
import { ILoadsState } from '@models/loads.model';
import { loadReducer } from '@reducers/load.reducer';
import { caseReducer } from '@reducers/case.reducer';
import { SiteAPIEffects } from './effects/site.effects';
import { UsersAPIEffects } from './effects/users.effects';
import { ISitesState } from './models/sites.model';
import { IUsersState } from './models/user.model';
import { siteReducer } from './reducers/site.reducer';
import { userReducer } from './reducers/users.reducer';
import {
  CASE_STATE_NAME,
  CONTRACT_STATE_NAME,
  LOAD_STATE_NAME,
  ORANISATION_STATE_NAME,
  SITE_STATE_NAME,
  TRANSPORT_ORDER_STATE_NAME,
  TRANSPORT_PERMIT_STATE_NAME,
  USER_STATE_NAME,
} from './state.constants';
import { sharedReducer } from './store/shared/shared.reducer';
import { SHARED_STATE_NAME } from './store/shared/shared.selector';
import { ISharedState } from './store/shared/shared.state';
import { IOrganisationState } from '@models/organisations.model';
import { OrganisationAPIEffects } from '@effects/organisation.effects';
import { organisationReducer } from '@reducers/organisation.reducer';
import { IContractState } from '@models/contract.model';
import { transportOrderReducer } from '@reducers/transport-order.reducer';
import { TransportOrderEffects } from '@effects/transport-order.effects';
import { ITransportOrderState } from '@models/transport-order.model';
import { contractReducer } from '@reducers/contract.reducer';
import { ContractAPIEffects } from '@effects/contract.effects';
import { ITransportPermitState } from '@models/transport-permit.model';
import { transportPermitReducer } from '@reducers/transport-permit.reducer';
import { TransportPermitEffects } from '@effects/transport-permits.effects';
import { ICasesState } from '@models/cases.model';

export interface IAppState {
  [SHARED_STATE_NAME]: ISharedState;
  [USER_STATE_NAME]: IUsersState;
  [SITE_STATE_NAME]: ISitesState;
  [CASE_STATE_NAME]: ICasesState;
  [LOAD_STATE_NAME]: ILoadsState;
  [ORANISATION_STATE_NAME]: IOrganisationState;
  [CONTRACT_STATE_NAME]: IContractState;
  [TRANSPORT_ORDER_STATE_NAME]: ITransportOrderState;
  [TRANSPORT_PERMIT_STATE_NAME]: ITransportPermitState;
}

export const AppReducer = {
  [SHARED_STATE_NAME]: sharedReducer,
  [USER_STATE_NAME]: userReducer,
  [SITE_STATE_NAME]: siteReducer,
  [LOAD_STATE_NAME]: loadReducer,
  [CASE_STATE_NAME]: caseReducer,
  [ORANISATION_STATE_NAME]: organisationReducer,
  [CONTRACT_STATE_NAME]: contractReducer,
  [TRANSPORT_ORDER_STATE_NAME]: transportOrderReducer,
  [TRANSPORT_PERMIT_STATE_NAME]: transportPermitReducer,
};

export const AppEffect = [
  UsersAPIEffects,
  SiteAPIEffects,
  LoadAPIEffects,
  CaseAPIEffects,
  OrganisationAPIEffects,
  ContractAPIEffects,
  TransportOrderEffects,
  TransportPermitEffects,
];
